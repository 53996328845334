@import './reset.css';

// media
$tablet: 768px;
$mobile: 576px;

// color
$crusoe		: #2b580c;
$oxley		: #639a67;
$teagreen	: #d8ebb5;
$straw		: #d9bf77;
$black		: #333333;
$back		: #FEFBF5;

html, body {
	position: relative;
	width: 100%;
	background-color: #FEFBF5;
	scroll-behavior: smooth;
}

body {
	color: $black;
	margin: 0;
	padding: 15px;
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

a{
	color: $black;
	text-decoration-line: unset;

	&:hover{
		text-decoration-line: underline;
	}
}

.container{
	max-width: 1200px;
	padding: 100px 0;
	margin: 0 auto;
}

.title{
	font-size: 8rem;
	position: relative;
	font-weight: 800;
	line-height: 0.9em;
	z-index: 1;

	&.right{
		text-align: right;
	}

	@media (max-width: $tablet) {
		font-size: 4rem;
	}
}

.link{
	font-size: 4rem;
	@media (max-width: $tablet) {
		font-size: 2rem;
	}
}

.drop{
	animation-name: Drop;
	animation-duration: 1.5s;
	animation-fill-mode: forwards;
	word-break: break-all;
	position: relative;
	top: -500px;
}

.drop.a{
	animation-delay: 1s;
}
.drop.b{
	animation-delay: 0.5s;
}

@keyframes Drop{
	from{
		top: -500px;
	}
	to{
		top: 0px;
	}
}

@font-face{
	font-family: RisollaCalisto;
	src: url('./font/RisollaCalisto.ttf');
}
.RisollaCalisto{
	font-family: RisollaCalisto;
}
